import React, { createContext, useState } from "react";
import api from "../api";

const CatalogContext = createContext();

export const CatalogProvider = ({ children }) => {
  const [catalogs, setCatalogs] = useState({
    data: [],
    loading: false,
    error: null,
  });
  const [error, setError] = useState(null);

  const fetchCatalogs = async () => {
    setCatalogs({ ...catalogs, loading: true });
    try {
      const response = await api.get("/api/catalogs-with-brands");
      setCatalogs({ data: response.data.data, loading: false, error: null });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch catalogs. Please try again later.";
      console.error("Fetch Catalogs Error: ", errorMessage);
      setCatalogs({ data: [], loading: false, error: errorMessage });
    }
  };

  const fetchCatalogById = async (catalogId) => {
    setCatalogs({ ...catalogs, loading: true });
    try {
      const response = await api.get(`/api/catalogs-with-brands/${catalogId}`);
      return response.data.data; // Return the specific catalog
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch catalog. Please try again later.";
      console.error("Fetch Catalog Error: ", errorMessage);
      setCatalogs({ ...catalogs, loading: false, error: errorMessage });
      throw new Error(errorMessage);
    }
  };

  const fetchCatalogsByBrandId = async (brandId) => {
    setError(null);
    setCatalogs((prevState) => ({ ...prevState, loading: true }));

    try {
      const response = await api.get(`/api/catalogs/brand/${brandId}`);
      setCatalogs({
        data: response.data.data,
        loading: false,
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch catalogs. Please try again later.";
      console.error("Fetch Catalogs Error: ", errorMessage);
      setError(errorMessage);
      setCatalogs((prevState) => ({ ...prevState, loading: false }));
    }
  };
  // Function to clear catalogs
  const clearCatalogs = () => {
    setCatalogs({ data: [], loading: false });
  };

  return (
    <CatalogContext.Provider
      value={{
        catalogs,
        fetchCatalogs,
        fetchCatalogById,
        fetchCatalogsByBrandId,
        clearCatalogs,
        loading: catalogs.loading,
        error,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

export default CatalogContext;
