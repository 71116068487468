import React, { useContext, useState, useEffect, useCallback } from "react";
import Navbar from "../components/Navbar";
import AuthContext from "../context/AuthContext";
import InventoryContext from "../context/InventoryContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddEditInventoryModal from "../components/AddEditInventoryModal";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Loader from "../components/Loader";

const Inventory = () => {
  const { isAuthenticated, loading: authLoading } = useContext(AuthContext);
  const { brandInventories, fetchInventoryCountsByBrand } =
    useContext(InventoryContext);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState(
    searchParams.get("search") || ""
  );
  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1
  );
  const [currentInventory, setCurrentInventory] = useState(null);

  const searchTerm = searchParams.get("search") || "";
  const totalPages = brandInventories.pagination?.totalPages || 1;

  const fetchInventories = useCallback(
    (page, search) => {
      fetchInventoryCountsByBrand(page, search);
    },
    [fetchInventoryCountsByBrand]
  );

  useEffect(() => {
    if (isAuthenticated) {
      fetchInventories(currentPage, searchTerm);
    }
  }, [isAuthenticated, fetchInventories, currentPage, searchTerm]);

  const handleSearch = () => {
    setSearchParams({ search: searchInput, page: 1 });
    setCurrentPage(1);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setSearchParams({ search: searchTerm, page: newPage });
      setCurrentPage(newPage);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setSearchParams({ search: searchTerm, page: newPage });
      setCurrentPage(newPage);
    }
  };

  const handleBrandClick = (brandId) => {
    navigate(`/inventories/brand/${brandId}`);
  };

  const handleAddInventory = () => {
    setCurrentInventory(null);
    setShowModal(true);
  };

  const handleUpdateInventory = (group) => {
    setCurrentInventory({ brand_id: group.brand?.brand_id });
    setShowModal(true);
  };

  if (authLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader size={50} color={"#4A90E2"} message="Authenticating..." />
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center justify-between">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Inventories by Brand
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all brands and their total inventory quantities.
            </p>
          </div>

          <button
            onClick={handleAddInventory}
            className="mt-4 sm:mt-0 bg-green-500 text-white px-3 py-2 rounded-md shadow-sm hover:bg-green-400"
          >
            + Update Inventory
          </button>
        </div>

        <div className="relative mt-4 flex shadow-sm">
          <input
            type="text"
            className="block w-full rounded-l-md border pl-6 pr-3 py-2 focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Search by Brand Name"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button
            onClick={handleSearch}
            className="px-3 py-2 bg-gray-50 text-gray-500 hover:bg-gray-100 rounded-r-md"
          >
            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>

        {brandInventories.error && (
          <div className="mt-4 text-red-600">{brandInventories.error}</div>
        )}

        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {brandInventories.loading ? (
                <Loader
                  size={50}
                  color={"#4A90E2"}
                  message="Loading inventories..."
                />
              ) : brandInventories.data.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                        Brand Name
                      </th>
                      <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                        Total Quantity
                      </th>
                      <th className="py-3 px-6 text-right text-sm font-semibold text-gray-900">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {brandInventories.data.map((group) => (
                      <tr
                        key={group.brand?.brand_id}
                        className="cursor-pointer hover:bg-gray-100"
                        onClick={() => handleBrandClick(group.brand?.brand_id)}
                      >
                        <td className="py-3 px-6 text-sm font-medium text-gray-900">
                          {group.brand?.name || "Unknown"}
                        </td>
                        <td className="py-3 px-6 text-sm text-gray-500">
                          {group.totalQty || 0}
                        </td>
                        <td className="py-3 px-6 text-right">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUpdateInventory(group);
                            }}
                            className="bg-blue-500 text-white px-2 py-1 rounded"
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center py-4 text-gray-500">
                  No inventories found. Try adjusting your search term.
                </div>
              )}
            </div>
          </div>
        </div>

        {totalPages > 1 && (
          <div className="flex justify-between items-center mt-4">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="bg-gray-200 text-gray-800 py-2 px-4 rounded"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="bg-gray-200 text-gray-800 py-2 px-4 rounded"
            >
              Next
            </button>
          </div>
        )}
      </div>

      {showModal && (
        <AddEditInventoryModal
          showModal={showModal}
          setShowModal={setShowModal}
          currentInventory={currentInventory}
          fetchInventories={fetchInventories}
        />
      )}
    </div>
  );
};

export default Inventory;
