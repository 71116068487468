import React, { useState, useEffect } from "react";
import api from "../api";
import Navbar from "../components/Navbar";
import { ClipLoader } from "react-spinners";

const BizomFileUpload = () => {
  const [distributors, setDistributors] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadType, setUploadType] = useState("stock-at-outlet");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // "success" or "error"
  const [loading, setLoading] = useState(false); // Track loading state

  useEffect(() => {
    const fetchDistributors = async () => {
      try {
        const response = await api.get("/api/distributors");
        setDistributors(response.data);
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
    };

    fetchDistributors();
  }, []);

  const handleDistributorChange = (e) => {
    setSelectedDistributor(e.target.value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadTypeChange = (e) => {
    setUploadType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDistributor || !selectedFile) {
      setMessage("Please select a distributor and a file.");
      setMessageType("error");
      return;
    }

    const formData = new FormData();
    formData.append("distributor_id", selectedDistributor);
    formData.append("file", selectedFile);

    setLoading(true);

    try {
      const endpointMap = {
        "stock-at-outlet": "/api/bizom/upload/stock-at-outlet",
        "activity-report": "/api/bizom/upload/activity-report",
        "secondary-sales-report": "/api/bizom/upload/secondary-sales-report",
      };

      const response = await api.post(endpointMap[uploadType], formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setMessage(response.data.message);
      setMessageType("success");
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage(
        error.response?.data?.error ||
          "An error occurred while uploading the file."
      );
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="bizom-file-upload flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Bizom File Upload
        </h1>

        <form
          className="bg-white shadow-md rounded-lg p-6 w-full max-w-md"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label
              htmlFor="distributor"
              className="block text-gray-700 font-bold mb-2"
            >
              Select Distributor
            </label>
            <select
              id="distributor"
              value={selectedDistributor}
              onChange={handleDistributorChange}
              className="w-full border border-gray-300 rounded-md p-2"
            >
              <option value="">-- Select Distributor --</option>
              {distributors.map((distributor) => (
                <option
                  key={distributor.distributor_id}
                  value={distributor.distributor_id}
                >
                  {distributor.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="uploadType"
              className="block text-gray-700 font-bold mb-2"
            >
              Select Upload Type
            </label>
            <select
              id="uploadType"
              value={uploadType}
              onChange={handleUploadTypeChange}
              className="w-full border border-gray-300 rounded-md p-2"
            >
              <option value="stock-at-outlet">Stock At Outlet</option>
              <option value="activity-report">Activity Report</option>
              <option value="secondary-sales-report">
                Secondary Sales Report
              </option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="file"
              className="block text-gray-700 font-bold mb-2"
            >
              Select File
            </label>
            <input
              type="file"
              id="file"
              accept=".csv, .xlsx, .xls"
              onChange={handleFileChange}
              className="w-full border border-gray-300 rounded-md p-2"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded hover:bg-indigo-700"
            disabled={loading}
          >
            {loading ? <ClipLoader size={24} color="#ffffff" /> : "Upload"}
          </button>
        </form>

        {message && (
          <div
            className={`mt-6 px-4 py-3 rounded relative w-full max-w-md ${
              messageType === "success"
                ? "bg-green-100 border border-green-400 text-green-700"
                : "bg-red-100 border border-red-400 text-red-700"
            }`}
          >
            <span className="block sm:inline">{message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default BizomFileUpload;
