import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import AuthContext from "../context/AuthContext";
import InventoryContext from "../context/InventoryContext";
import AddEditInventoryModal from "../components/AddEditInventoryModal";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Loader from "../components/Loader";

const BrandInventory = () => {
  const { isAuthenticated, loading: authLoading } = useContext(AuthContext);
  const { brandInventories, fetchInventoriesByBrand } =
    useContext(InventoryContext);

  const navigate = useNavigate();
  const { brandId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showModal, setShowModal] = useState(false);
  const [currentInventory, setCurrentInventory] = useState(null);
  const [brandName, setBrandName] = useState("Unknown Brand");
  const [searchInput, setSearchInput] = useState(
    searchParams.get("search") || ""
  );

  const searchTerm = searchParams.get("search") || "";

  // Fetch inventories by brand when the component mounts or dependencies change
  const fetchInventories = useCallback(() => {
    if (brandId) {
      fetchInventoriesByBrand(brandId, searchTerm);
    }
  }, [fetchInventoriesByBrand, brandId, searchTerm]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchInventories();
    }
  }, [isAuthenticated, fetchInventories]);

  useEffect(() => {
    if (brandInventories.data?.length > 0) {
      setBrandName(
        brandInventories.data[0]?.catalog?.catalogBrand?.name || "Unknown Brand"
      );
    } else {
      setBrandName("Unknown Brand");
    }
  }, [brandInventories.data]);

  const handleSearch = () => {
    setSearchParams({ search: searchInput });
    fetchInventories();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  const handleCatalogClick = (catalogId) => {
    navigate(`/inventories/catalog/${catalogId}`);
  };

  const handleAddInventory = () => {
    setCurrentInventory({ brand_id: brandId });
    setShowModal(true);
  };

  const handleUpdateInventory = (group) => {
    setCurrentInventory({
      brand_id: group.catalog?.brand_id,
      catalog_id: group.catalog?.catalog_id,
    });
    setShowModal(true);
  };

  const handleBackToInventory = () => navigate("/inventories");

  if (authLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader size={50} color="#4A90E2" message="Authenticating..." />
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:p-6 lg:p-8">
        <button
          onClick={handleBackToInventory}
          className="inline-flex items-center px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md"
        >
          &larr; Back to Inventory
        </button>

        <div className="sm:flex sm:items-center justify-between pt-4">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Inventories for Brand: {brandName}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all SKUs under this brand and their total quantities.
            </p>
          </div>

          <button
            onClick={handleAddInventory}
            className="bg-green-500 text-white px-3 py-2 rounded-md shadow-sm hover:bg-green-400"
          >
            + Add Inventory
          </button>
        </div>

        <div className="relative mt-4 flex shadow-sm">
          <input
            type="text"
            className="block w-full rounded-l-md border pl-6 pr-3 py-2 focus:border-indigo-500 focus:ring-indigo-500"
            placeholder="Search by Catalog ID or Product Name"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button
            onClick={handleSearch}
            className="px-3 py-2 bg-gray-50 text-gray-500 hover:bg-gray-100"
          >
            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>

        <div className="mt-8">
          {brandInventories.loading ? (
            <Loader
              size={50}
              color="#4A90E2"
              message="Loading inventories..."
            />
          ) : brandInventories.error ? (
            <div className="text-red-600">{brandInventories.error}</div>
          ) : brandInventories.data?.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3 px-6 text-left text-sm font-semibold">
                    Catalog ID
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-semibold">
                    Product Name
                  </th>
                  <th className="py-3 px-6 text-left text-sm font-semibold">
                    Total Quantity
                  </th>
                  <th className="py-3 px-6 text-right text-sm font-semibold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {brandInventories.data.map((group) => (
                  <tr
                    key={group.catalog?.catalog_id}
                    onClick={() =>
                      handleCatalogClick(group.catalog?.catalog_id)
                    }
                    className="cursor-pointer hover:bg-gray-50"
                  >
                    <td className="py-3 px-6">
                      {group.catalog?.catalog_id || "N/A"}
                    </td>
                    <td className="py-3 px-6">
                      {group.catalog?.product_name || "N/A"}
                    </td>
                    <td className="py-3 px-6">{group.totalQty || 0}</td>
                    <td className="py-3 px-6 text-right">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUpdateInventory(group);
                        }}
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center py-6">
              No inventories found for this brand.
            </div>
          )}
        </div>

        {showModal && (
          <AddEditInventoryModal
            showModal={showModal}
            setShowModal={setShowModal}
            currentInventory={currentInventory}
            fetchInventories={fetchInventories}
          />
        )}
      </div>
    </div>
  );
};

export default BrandInventory;
