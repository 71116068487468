import React, { useContext, useEffect, useState } from "react";
import InventoryContext from "../context/InventoryContext";
import Loader from "../components/Loader";
import AddEditInventoryModal from "../components/AddEditInventoryModal";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";

const CatalogDetails = () => {
  const { catalogId } = useParams();
  const { catalogInventories, fetchInventoriesByCatalog } =
    useContext(InventoryContext);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [currentInventory, setCurrentInventory] = useState(null);

  useEffect(() => {
    if (catalogId) {
      fetchInventoriesByCatalog(catalogId);
    }
  }, [catalogId, fetchInventoriesByCatalog]);

  const brandName =
    catalogInventories.data[0]?.inventoryCatalog?.catalogBrand?.name ||
    "Unknown Brand";
  const productName =
    catalogInventories.data[0]?.inventoryCatalog?.product_name ||
    "Unknown Product";
  const brandId =
    catalogInventories.data[0]?.inventoryCatalog?.catalogBrand?.brand_id || "";

  const totalQuantity = catalogInventories.data.reduce(
    (sum, inventory) => sum + Number(inventory.qty || 0),
    0
  );

  const safeFormatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? "Invalid Date" : format(date, "dd MMM yyyy");
  };

  const handleBackToInventory = () => {
    navigate(`/inventories/brand/${brandId}`);
  };

  const handleUpdateInventory = () => {
    const inventory = catalogInventories.data[0].inventoryCatalog;
    setCurrentInventory({
      brand_id: inventory?.brand_id,
      catalog_id: inventory?.catalog_id,
    });
    setShowModal(true);
  };

  return (
    <div className="p-6 lg:p-8 bg-gray-100 min-h-screen">
      {/* Back Button */}
      <div className="mb-4">
        <button
          onClick={handleBackToInventory}
          className="inline-flex items-center px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md"
        >
          &larr; Back to Inventory
        </button>
      </div>

      {/* Title Section */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">{productName}</h2>
        <p className="text-gray-600">
          <span className="font-semibold">Catalog ID:</span> {catalogId}
        </p>
        <p className="text-gray-600">
          <span className="font-semibold">Brand:</span> {brandName}
        </p>
        <p className="text-lg font-semibold text-gray-800 mt-2">
          Total Quantity: {totalQuantity}
        </p>
      </div>

      {/* Update Inventory Button */}
      <div className="mb-6">
        <button
          onClick={handleUpdateInventory}
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md shadow-sm"
        >
          Update Inventory
        </button>
      </div>

      {/* Inventory Table */}
      <div className="bg-white shadow-md rounded-lg p-6">
        {catalogInventories.loading ? (
          <Loader size={50} color="#4A90E2" message="Loading inventories..." />
        ) : catalogInventories.error ? (
          <div className="text-red-600">{catalogInventories.error}</div>
        ) : catalogInventories.data?.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-900">
                  Date
                </th>
                <th className="py-3 px-4 text-left text-sm font-semibold text-gray-900">
                  Quantity
                </th>
                <th className="py-3 px-4 text-right text-sm font-semibold text-gray-900">
                  Ref ID
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {catalogInventories.data.map((inventory) => (
                <tr key={inventory.id} className="hover:bg-gray-50">
                  <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-700">
                    {safeFormatDate(inventory.date_of_movement)}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-700">
                    {Number(inventory.qty) || 0}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-700 text-right">
                    {inventory.ref_id || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center py-6 text-gray-500">
            No inventories found for this catalog.
          </div>
        )}
      </div>

      {/* Add/Edit Inventory Modal */}
      {showModal && (
        <AddEditInventoryModal
          showModal={showModal}
          setShowModal={setShowModal}
          currentInventory={currentInventory}
          fetchInventories={() => fetchInventoriesByCatalog(catalogId)}
        />
      )}
    </div>
  );
};

export default CatalogDetails;
