import React, { useContext, useEffect, useState } from "react";
import OrderContext from "../context/OrderContext";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import queryString from "query-string";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { ClipLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateOrderModal from "../components/UpdateOrderModal";

const Orders = () => {
  const {
    orders,
    fetchOrders,
    handleUpdateOrder, // Use the unified update function
    loading: orderLoading,
  } = useContext(OrderContext);

  // State variables for search and pagination
  const [searchInput, setSearchInput] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // State variables for modals
  const [orderToUpdate, setOrderToUpdate] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const parsedParams = queryString.parse(location.search);
    const newSearchTerm = parsedParams.search || "";
    const newCurrentPage = parsedParams.page ? parseInt(parsedParams.page) : 1;

    setSearchTerm(newSearchTerm);
    setCurrentPage(newCurrentPage);

    fetchOrders(newCurrentPage, newSearchTerm);
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    setSearchInput(searchTerm);
  }, [searchTerm]);

  // Handler to navigate to add order page
  const handleAddOrder = () => {
    navigate("/orders/add");
  };

  // Handler to open the update modal
  const handleOpenUpdateModal = (order, e) => {
    e.stopPropagation();
    setOrderToUpdate(order);
    setShowUpdateModal(true);
  };

  // Handler to close the update modal
  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setOrderToUpdate(null);
  };

  // Search handlers
  const handleSearch = () => {
    const query = queryString.stringify({ search: searchInput, page: 1 });
    navigate(`?${query}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Helper to format status
  const formatStatus = (status) => {
    switch (status.toLowerCase()) {
      case "placed":
        return "PLACED";
      case "cancelled":
        return "CANCELLED";
      case "received_inventory":
        return "RECEIVED INVENTORY";
      case "received_invoice":
        return "RECEIVED INVOICE";
      case "delivered":
        return "DELIVERED";
      default:
        return status;
    }
  };

  // Pagination calculations
  const itemsPerPage = orders.pagination?.itemsPerPage || 10;
  const totalItems = orders.pagination?.totalItems || 0;
  const totalPages = orders.pagination?.totalPages || 0;
  const startItem = totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  // Pagination handlers
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const query = queryString.stringify({
        search: searchTerm,
        page: newPage,
      });
      navigate(`?${query}`);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      const query = queryString.stringify({
        search: searchTerm,
        page: newPage,
      });
      navigate(`?${query}`);
    }
  };

  // Navigate to order details
  const handleRowClick = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  // Function to handle the update logic from the modal
  const handleUpdate = async ({
    order_id,
    updates,
    invoiceId,
    invoiceFile,
  }) => {
    await handleUpdateOrder({ order_id, updates, invoiceId, invoiceFile });
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:p-6 lg:p-8">
        <div className="sm:flex sm:items-center justify-between">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Orders
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the orders including their details.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              onClick={handleAddOrder}
              type="button"
              className="block rounded-md bg-green-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-400"
            >
              + Add Order
            </button>
          </div>
        </div>

        {/* Search Input */}
        <div className="relative mt-4 rounded-md shadow-sm">
          <input
            type="text"
            className="block w-full rounded-md border border-black pl-6 pr-10 py-2"
            placeholder="Search by Store Name, Brand Name, or State"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button
            onClick={handleSearch}
            className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-200 hover:bg-gray-300 rounded-r-md"
          >
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        {/* Table */}
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Sr No
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Order ID
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Store Name
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Brand Name
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Date of Order
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      State
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {orderLoading && orders.data.length === 0 ? (
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        <div className="flex items-center justify-center">
                          <ClipLoader size={35} color={"#4A90E2"} />
                          <span className="ml-3 text-gray-600">
                            Loading orders...
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : orders.data.length > 0 ? (
                    orders.data.map((order, index) => (
                      <tr
                        key={order.id}
                        onClick={() => handleRowClick(order.order_id)}
                        className="cursor-pointer hover:bg-gray-100"
                      >
                        <td className="whitespace-wrap py-3 px-6 text-sm font-medium text-gray-900">
                          {(currentPage - 1) * itemsPerPage + index + 1}
                        </td>
                        <td className="whitespace-wrap py-3 px-6 text-sm font-medium text-gray-900">
                          {order.order_id}
                        </td>
                        <td className="whitespace-wrap py-3 px-6 text-sm text-gray-500">
                          {order.orderStore ? order.orderStore.title : "N/A"}
                        </td>
                        <td className="whitespace-wrap py-3 px-6 text-sm text-gray-500">
                          {order.orderBrand ? order.orderBrand.name : "N/A"}
                        </td>
                        <td className="whitespace-wrap py-3 px-6 text-sm text-gray-500">
                          {new Date(order.date_of_order).toLocaleDateString()}
                        </td>
                        <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500">
                          {formatStatus(order.state)}
                        </td>
                        <td className="whitespace-nowrap py-3 px-6 text-left text-sm font-medium">
                          <button
                            onClick={(e) => handleOpenUpdateModal(order, e)}
                            className="bg-blue-500 text-white py-1 px-2 rounded"
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        No orders found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Pagination Component */}
              <nav
                aria-label="Pagination"
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{startItem}</span> to{" "}
                    <span className="font-medium">{endItem}</span> of{" "}
                    <span className="font-medium">{totalItems}</span> results
                  </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 
                    ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                      currentPage === 1
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white"
                    }`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages || totalPages === 0}
                    className={`relative ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 
                    ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                      currentPage === totalPages || totalPages === 0
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white"
                    }`}
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* Update Modal */}
        {showUpdateModal && orderToUpdate && (
          <UpdateOrderModal
            isOpen={showUpdateModal}
            onClose={handleCloseUpdateModal}
            order={orderToUpdate}
            onUpdate={handleUpdate}
          />
        )}

        {/* Toast Notifications */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default Orders;
