import React, { createContext, useState } from "react";
import api from "../api";

const BrandContext = createContext();

export const BrandProvider = ({ children }) => {
  const [brands, setBrands] = useState({
    data: [],
    loading: false,
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
    },
  });
  const [error, setError] = useState(null);

  // Fetch brands with pagination and search
  const fetchBrands = async (page = 1, searchTerm = "") => {
    setError(null);
    setBrands((prevState) => ({ ...prevState, loading: true }));

    try {
      const response = await api.get("/api/brands", {
        params: { page, limit: 10, search: searchTerm },
      });

      const pagination = response.data.pagination || {};
      setBrands({
        data: response.data.data,
        loading: false,
        pagination: {
          totalItems: pagination.totalItems || 0,
          totalPages: pagination.totalPages || 0,
          currentPage: pagination.currentPage || 1,
          itemsPerPage: pagination.itemsPerPage || 10,
        },
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch brands. Please try again later.";
      console.error("Fetch Brands Error: ", errorMessage);
      setError(errorMessage);
      setBrands((prevState) => ({ ...prevState, loading: false }));
    }
  };

  // Search brands
  const searchBrands = async (query) => {
    if (!query || query.trim() === "") {
      return [];
    }
    try {
      const response = await api.get("/api/brands/search", {
        params: { q: query },
      });
      return response.data.data;
    } catch (error) {
      console.error("Search Brands Error:", error);
      setError(
        error.response?.data?.message ||
          "Failed to search brands. Please try again."
      );
      return [];
    }
  };
  
  return (
    <BrandContext.Provider
      value={{
        brands,
        fetchBrands,
        searchBrands,
        loading: brands.loading,
        error,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

export default BrandContext;
