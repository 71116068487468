import React, { createContext, useState, useCallback } from "react";
import api from "../api";

const InventoryContext = createContext();

export const InventoryProvider = ({ children }) => {
  // State for grouped inventories
  const [inventories, setInventories] = useState({
    data: [],
    loading: false,
    error: null,
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
    },
  });

  const [brandInventories, setBrandInventories] = useState({
    data: [],
    loading: false,
    error: null,
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
    },
  });

  // State for catalog-specific inventories
  const [catalogInventories, setCatalogInventories] = useState({
    data: [],
    loading: false,
    error: null,
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
    },
  });

  const limit = 50;

  // Fetch grouped inventories with pagination and search
  const fetchInventories = useCallback(
    async (page = 1, searchTerm = "") => {
      setInventories((prevState) => ({
        ...prevState,
        loading: true,
        error: null,
      }));

      try {
        const response = await api.get("/api/inventory", {
          params: { page, limit, search: searchTerm },
        });

        const pagination = response.data.pagination || {};
        setInventories({
          data: response.data.data,
          loading: false,
          error: null,
          pagination: {
            totalItems: pagination.totalItems || 0,
            totalPages: pagination.totalPages || 0,
            currentPage: pagination.currentPage || 1,
            itemsPerPage: pagination.itemsPerPage || limit,
          },
        });
        console.log("inventory", response.data);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "Failed to fetch inventories. Please try again later.";
        setInventories((prevState) => ({
          ...prevState,
          loading: false,
          error: errorMessage,
        }));
      }
    },
    [limit]
  );

  // Fetch inventories by specific catalog
  const fetchInventoriesByCatalog = useCallback(async (catalogId) => {
    setCatalogInventories({
      data: [],
      loading: true,
      error: null,
    });

    try {
      const response = await api.get(`/api/inventory/catalog/${catalogId}`);
      console.log("data", response.data);
      setCatalogInventories({
        data: response.data.data,
        loading: false,
        error: null,
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch inventories for the catalog.";
      setCatalogInventories({
        data: [],
        loading: false,
        error: errorMessage,
      });
    }
  }, []);

  // Add Inventory
  const addInventory = useCallback(
    async (inventoryData) => {
      try {
        await api.post("/api/inventory", inventoryData);
        fetchInventories();
      } catch (error) {
        console.error("Error adding inventory:", error);
      }
    },
    [fetchInventories]
  );

  // Update Inventory
  const updateInventory = useCallback(
    async (id, updatedData) => {
      try {
        await api.put(`/api/inventory/${id}`, updatedData);
        fetchInventories();
      } catch (error) {
        console.error("Error updating inventory:", error);
      }
    },
    [fetchInventories]
  );

  // Delete Inventory
  const deleteInventory = useCallback(
    async (id) => {
      try {
        await api.patch(`/api/inventory/deactivate/${id}`);
        fetchInventories();
      } catch (error) {
        console.error("Error deleting inventory:", error);
      }
    },
    [fetchInventories]
  );

  // Fetch inventory counts grouped by brand
  const fetchInventoryCountsByBrand = useCallback(
    async (page = 1, searchTerm = "") => {
      setBrandInventories((prevState) => ({
        ...prevState,
        loading: true,
        error: null,
      }));

      try {
        const response = await api.get("/api/inventory/brands", {
          params: { page, limit, search: searchTerm },
        });

        const pagination = response.data.pagination || {
          totalItems: 0,
          totalPages: 0,
          currentPage: 1,
          itemsPerPage: limit,
        };

        setBrandInventories({
          data: response.data.data,
          loading: false,
          error: null,
          pagination,
        });
      } catch (error) {
        setBrandInventories({
          data: [],
          loading: false,
          error:
            error.response?.data?.message || "Failed to fetch inventories.",
        });
      }
    },
    [limit]
  );

  // Fetch inventories by brand ID
  const fetchInventoriesByBrand = useCallback(
    async (brandId, searchTerm = "") => {
      setBrandInventories({ data: [], loading: true, error: null });

      try {
        const response = await api.get(`/api/inventory/brand/${brandId}`, {
          params: { search: searchTerm },
        });

        setBrandInventories({
          data: response.data.data,
          loading: false,
          error: null,
        });
      } catch (error) {
        setBrandInventories({
          data: [],
          loading: false,
          error: error.response?.data?.error || "Failed to fetch inventories.",
        });
      }
    },
    []
  );

  // Memoize context value
  const contextValue = React.useMemo(
    () => ({
      inventories,
      fetchInventories,
      fetchInventoryCountsByBrand,
      catalogInventories,
      fetchInventoriesByCatalog,
      brandInventories,
      fetchInventoriesByBrand,
      addInventory,
      updateInventory,
      deleteInventory,
    }),
    [
      inventories,
      fetchInventories,
      fetchInventoryCountsByBrand,
      catalogInventories,
      fetchInventoriesByCatalog,
      brandInventories,
      fetchInventoriesByBrand,
      addInventory,
      updateInventory,
      deleteInventory,
    ]
  );

  return (
    <InventoryContext.Provider value={contextValue}>
      {children}
    </InventoryContext.Provider>
  );
};

export default InventoryContext;
