import React, { createContext, useState } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const navigate = useNavigate(); 
  const [stores, setStores] = useState({
    data: [],
    loading: false,
    pagination: {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      itemsPerPage: 10,
    },
  });
  const [error, setError] = useState(null);
  const limit = 10; // Set limit per page

  // Helper function to update the store state
  const setState = (updatedState) => {
    setStores((prevState) => ({ ...prevState, ...updatedState }));
  };

  // Fetch stores with pagination and search
  const fetchStores = async (page = 1, searchTerm = "") => {
    setError(null);
    setState({ loading: true });

    try {
      const response = await api.get(`/api/stores`, {
        params: { page, limit, search: searchTerm },
      });

      const pagination = response.data.pagination || {};
      setState({
        data: response.data.data,
        loading: false,
        pagination: {
          totalItems: pagination.totalItems || 0,
          totalPages: pagination.totalPages || 0,
          currentPage: pagination.currentPage || 1,
          itemsPerPage: pagination.itemsPerPage || limit,
        },
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch stores. Please try again later.";
      console.error("Fetch Stores Error: ", errorMessage);
      setError(errorMessage);
      setState({ loading: false });
    }
  };

  // Add a new store using URL
  const addStore = async (url) => {
    if (!url) {
      throw new Error("Store URL is required");
    }

    try {
      const response = await api.post("/api/store/add-from-url", { url });
      const newStoreId = response.data.data.store_id;
      navigate(`/stores/${newStoreId}`);
      await fetchStores(stores.pagination.currentPage);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle 409 Conflict (Store already exists)
        const existingStoreId = error.response.data.data.id;
        return {
          message: "Store already exists.",
          storeExists: true,
          existingStoreId,
        };
      }

      const errorMessage =
        error.response?.data?.message || "Failed to add store.";
      throw new Error(errorMessage);
    }
  };

  // Update an existing store
  const updateStore = async (id, storeData) => {
    if (!id || !storeData) {
      throw new Error("Store ID and data are required");
    }

    try {
      await api.put(`/api/stores/${id}`, storeData);
      await fetchStores(stores.pagination.currentPage);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update store.";
      throw new Error(errorMessage);
    }
  };

  // Delete a store (deactivate store in DB)
  const deleteStore = async (id) => {
    if (!id) {
      console.error("Store ID is required");
      throw new Error("Store ID is required");
    }

    try {
      console.log(`Deleting store with ID: ${id}`);
      await api.patch(`/api/stores/deactivate/${id}`);
      // After deleting, refetch the current page
      await fetchStores(stores.pagination.currentPage);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to delete store.";
      console.error("Error in deleteStore:", errorMessage);
      setError(errorMessage);
      throw new Error(errorMessage);
    }
  };

  // Search stores
  const searchStores = async (query) => {
    if (!query || query.trim() === "") {
      return [];
    }
    try {
      const response = await api.get(`/api/stores/search`, {
        params: { q: query },
      });
      return response.data.data;
    } catch (error) {
      console.error("Search Stores Error:", error);
      setError(
        error.response?.data?.message ||
          "Failed to search stores. Please try again."
      );
      return [];
    }
  };

  return (
    <StoreContext.Provider
      value={{
        stores,
        fetchStores,
        addStore,
        updateStore,
        deleteStore,
        loading: stores.loading,
        error,
        searchStores,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
