// src/components/EventCalendar.js

import React, { useContext, useState, useEffect, lazy, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import AuthContext from "../context/AuthContext";
import EventContext from "../context/EventContext";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import queryString from "query-string";
import { ClipLoader } from "react-spinners";
import HotspotContext from "../context/HotspotContext";

const AddEditEventModal = lazy(() => import("../components/AddEditEventModal"));
const ConfirmDeleteModal = lazy(() =>
  import("../components/ConfirmDeleteModal")
);

const Event = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const {
    events,
    fetchEvents,
    deleteEvent,
    loading: eventLoading,
  } = useContext(EventContext);
  const { hotspots, fetchHotspots } = useContext(HotspotContext);
  const [showModal, setShowModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [eventToDelete, setEventToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const parsedParams = queryString.parse(location.search);
    const newSearchTerm = parsedParams.search || "";
    const newCurrentPage = parsedParams.page ? parseInt(parsedParams.page) : 1;

    setSearchInput(newSearchTerm);
    setCurrentPage(newCurrentPage);

    if (isAuthenticated) {
      fetchEvents(newCurrentPage, newSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchHotspots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleAddEvent = () => {
    setCurrentEvent(null);
    setShowModal(true);
  };

  const handleEditEvent = (event, e) => {
    e.stopPropagation();
    setCurrentEvent(event);
    setShowModal(true);
  };

  const handleDeleteEvent = (event, e) => {
    e.stopPropagation();
    setEventToDelete(event);
    setShowDeleteModal(true);
  };

  const confirmDeleteEvent = async () => {
    try {
      await deleteEvent(eventToDelete.id);
      await fetchEvents(currentPage, searchInput);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleSearch = () => {
    const query = queryString.stringify({ search: searchInput, page: 1 });
    navigate(`?${query}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const itemsPerPage = events.pagination?.itemsPerPage || 10;
  const totalItems = events.pagination?.totalItems || 0;
  const totalPages = events.pagination?.totalPages || 0;
  const startItem = totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const query = queryString.stringify({
        search: searchInput,
        page: newPage,
      });
      navigate(`?${query}`);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      const query = queryString.stringify({
        search: searchInput,
        page: newPage,
      });
      navigate(`?${query}`);
    }
  };

  // Ensure that `hotspots` and `hotspots.loading` are defined
  const isLoading = eventLoading || (hotspots && hotspots.loading);

  return (
    <>
      <Navbar />
      <div className="p-4 sm:p-6 lg:p-8">
        {/* Header and Add Button */}
        <div className="sm:flex sm:items-center justify-between">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Events
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the events in your account including their event
              name, description, and other details.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              onClick={handleAddEvent}
              type="button"
              className="block rounded-md bg-green-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-400"
            >
              + Add Event
            </button>
          </div>
        </div>

        {/* Search Input */}
        <div className="relative mt-4 rounded-md shadow-sm">
          <input
            type="text"
            className="block w-full rounded-md border border-black pl-6 pr-10 py-2"
            placeholder="Search by Event Name"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button
            onClick={handleSearch}
            className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-200 hover:bg-gray-300 rounded-r-md"
          >
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        {/* Table */}
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Sr No
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Event Name
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Hotspot
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Vendor Name
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Start Time
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      End Time
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {isLoading ? (
                    <tr>
                      <td colSpan="9" className="text-center py-4">
                        <div className="flex items-center justify-center">
                          <ClipLoader size={35} color={"#4A90E2"} />
                          <span className="ml-3 text-gray-600">
                            Loading events...
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : events.data && events.data.length > 0 ? (
                    events.data.map((event, index) => (
                      <tr
                        key={event.id}
                        className="cursor-pointer hover:bg-gray-100"
                        onClick={() => {
                          // Optional: Handle row click if needed
                        }}
                      >
                        <td className="whitespace-wrap py-4 px-6 text-sm font-medium text-gray-900">
                          {(currentPage - 1) * itemsPerPage + index + 1}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm font-medium text-gray-900">
                          {event.event_name}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {event.description}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {event.eventHotspot.property_name}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {event.vendor_name}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {event.date}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {event.start_time}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {event.end_time}
                        </td>
                        <td className="whitespace-nowrap py-4 px-6 text-left text-sm font-medium">
                          <button
                            onClick={(e) => handleEditEvent(event, e)}
                            className="mr-2 bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-400"
                          >
                            Edit
                          </button>
                          <button
                            onClick={(e) => handleDeleteEvent(event, e)}
                            className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-400"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center py-4">
                        No events found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Pagination Component */}
              <nav
                aria-label="Pagination"
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{startItem}</span> to{" "}
                    <span className="font-medium">{endItem}</span> of{" "}
                    <span className="font-medium">{totalItems}</span> results
                  </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 
                    ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                      currentPage === 1
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white"
                    }`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages || totalPages === 0}
                    className={`relative ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 
                    ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                      currentPage === totalPages || totalPages === 0
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white"
                    }`}
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>

            {/* Modals */}
            <Suspense fallback={<div>Loading modal...</div>}>
              {showModal && (
                <AddEditEventModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  currentEvent={currentEvent}
                />
              )}
              {showDeleteModal && (
                <ConfirmDeleteModal
                  showModal={showDeleteModal}
                  setShowModal={setShowDeleteModal}
                  onConfirmDelete={confirmDeleteEvent}
                  itemToDelete={eventToDelete}
                  itemType="event"
                  itemNameKey="event_name"
                />
              )}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default Event;
