import React, { useContext, useState, useEffect, lazy, Suspense } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import AuthContext from "../context/AuthContext";
import HotspotContext from "../context/HotspotContext";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import queryString from "query-string";
import { ClipLoader } from "react-spinners";

const AddEditHotspotModal = lazy(() =>
  import("../components/AddEditHotspotModal")
);
const ConfirmDeleteModal = lazy(() =>
  import("../components/ConfirmDeleteModal")
);

const Hotspot = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const {
    hotspots,
    fetchHotspots,
    deleteHotspot,
    loading: hotspotLoading,
  } = useContext(HotspotContext);
  const [showModal, setShowModal] = useState(false);
  const [currentHotspot, setCurrentHotspot] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [hotspotToDelete, setHotspotToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Parse URL params and set search term and current page
  useEffect(() => {
    const parsedParams = queryString.parse(location.search);
    const newSearchTerm = parsedParams.search || "";
    const newCurrentPage = parsedParams.page ? parseInt(parsedParams.page) : 1;

    setSearchInput(newSearchTerm);

    // Fetch the hotspots based on the search term and page when URL params change
    if (isAuthenticated) {
      fetchHotspots(newCurrentPage, newSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, isAuthenticated]);

  const handleAddHotspot = () => {
    setCurrentHotspot(null);
    setShowModal(true);
  };

  const handleEditHotspot = (hotspot, e) => {
    e.stopPropagation(); // Prevent row click event
    setCurrentHotspot(hotspot);
    setShowModal(true);
  };

  const handleDeleteHotspot = (hotspot, e) => {
    e.stopPropagation(); // Prevent row click event
    setHotspotToDelete(hotspot);
    setShowDeleteModal(true);
  };

  const confirmDeleteHotspot = async () => {
    try {
      await deleteHotspot(
        hotspotToDelete.hotspot_id,
        hotspots.pagination.currentPage,
        searchInput
      );
      await fetchHotspots(hotspots.pagination.currentPage, searchInput);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting hotspot:", error);
      // Optionally, set an error state to display in the modal
    }
  };

  const handleSearch = () => {
    const query = queryString.stringify({ search: searchInput, page: 1 });
    navigate(`?${query}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Pagination handlers
  const itemsPerPage = hotspots.pagination.itemsPerPage || 10;
  const totalItems = hotspots.pagination.totalItems || 0;
  const totalPages = hotspots.pagination.totalPages || 0;
  const currentPage = hotspots.pagination.currentPage || 1;
  const startItem = totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      const query = queryString.stringify({
        search: searchInput,
        page: newPage,
      });
      navigate(`?${query}`);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      const query = queryString.stringify({
        search: searchInput,
        page: newPage,
      });
      navigate(`?${query}`);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:p-6 lg:p-8">
        {/* Header and Add Button */}
        <div className="sm:flex sm:items-center justify-between">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Hotspots
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the hotspots
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              onClick={handleAddHotspot}
              type="button"
              className="block rounded-md bg-green-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-400"
            >
              + Add Hotspot
            </button>
          </div>
        </div>

        {/* Search Input */}
        <div className="relative mt-4 rounded-md shadow-sm">
          <input
            type="text"
            className="block w-full rounded-md border border-black pl-6 pr-10 py-2"
            placeholder="Search by Hotspot Name"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button
            onClick={handleSearch}
            className="absolute inset-y-0 right-0 px-3 flex items-center bg-gray-200 hover:bg-gray-300 rounded-r-md"
          >
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        {/* Table */}
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Sr No
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Property Name
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Property Address
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Pincode
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Google Map Link
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Latitude, Longitude
                    </th>
                    <th className="py-3 px-6 text-left text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {hotspotLoading ? (
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        <div className="flex items-center justify-center">
                          <ClipLoader size={35} color={"#4A90E2"} />
                          <span className="ml-3 text-gray-600">
                            Loading hotspots...
                          </span>
                        </div>
                      </td>
                    </tr>
                  ) : hotspots.data && hotspots.data.length > 0 ? (
                    hotspots.data.map((hotspot, index) => (
                      <tr key={hotspot.hotspot_id}>
                        <td className="whitespace-wrap py-4 px-6 text-sm font-medium text-gray-900">
                          {(currentPage - 1) *
                            hotspots.pagination.itemsPerPage +
                            index +
                            1}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm font-medium text-gray-900">
                          {hotspot.property_name}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {hotspot.property_address}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {hotspot.pincode}
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          <a
                            href={hotspot.map_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            View Map
                          </a>
                        </td>
                        <td className="whitespace-wrap py-4 px-6 text-sm text-gray-500">
                          {hotspot.latitude}, {hotspot.longitude}
                        </td>
                        <td className="whitespace-nowrap py-4 px-6 text-left text-sm font-medium">
                          <button
                            onClick={(e) => handleEditHotspot(hotspot, e)}
                            className="mr-2 bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-400"
                          >
                            Edit
                          </button>
                          <button
                            onClick={(e) => handleDeleteHotspot(hotspot, e)}
                            className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-400"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center py-4">
                        No hotspots found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Pagination Component */}
              <nav
                aria-label="Pagination"
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{startItem}</span> to{" "}
                    <span className="font-medium">{endItem}</span> of{" "}
                    <span className="font-medium">{totalItems}</span> results
                  </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className={`relative inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 
                    ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                      currentPage === 1
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white"
                    }`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages || totalPages === 0}
                    className={`relative ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 
                    ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 ${
                      currentPage === totalPages || totalPages === 0
                        ? "bg-gray-200 cursor-not-allowed"
                        : "bg-white"
                    }`}
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* Modals */}
        <Suspense fallback={<div>Loading modal...</div>}>
          {showModal && (
            <AddEditHotspotModal
              showModal={showModal}
              setShowModal={setShowModal}
              currentHotspot={currentHotspot}
              currentPage={currentPage} // Pass current page
              searchInput={searchInput} // Pass current search term
            />
          )}
          {showDeleteModal && (
            <ConfirmDeleteModal
              showModal={showDeleteModal}
              setShowModal={setShowDeleteModal}
              onConfirmDelete={confirmDeleteHotspot}
              itemToDelete={hotspotToDelete}
              itemType="hotspot"
              itemNameKey="property_name"
            />
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default Hotspot;
