import React, { useState, useEffect, useContext } from "react";
import OrderContext from "../context/OrderContext";

const UpdateOrderModal = ({ isOpen, onClose, order }) => {
  const { updateOrderState, updateOrderFulfillment, createInvoice } =
    useContext(OrderContext);

  const [newStatus, setNewStatus] = useState("");
  const [newFulfillmentType, setNewFulfillmentType] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // To manage submission state
  const [modalError, setModalError] = useState(""); // To display error messages in the modal

  useEffect(() => {
    if (order) {
      console.log("Modal opened with order:", order); // Debugging
      setNewStatus(order.state ? order.state.toLowerCase() : "");
      setNewFulfillmentType(order.fulfillment_type || "");
      setInvoiceId(""); // Reset invoice ID on order change
      setInvoiceFile(null); // Reset invoice file on order change
      setModalError(""); // Reset modal error on order change
    }
  }, [order, isOpen]);

  const handleUpdate = async () => {
    // Clear previous modal errors
    setModalError("");

    // Check if at least one field is provided
    if (
      !newStatus.trim() &&
      !newFulfillmentType.trim() &&
      !invoiceId.trim() &&
      !invoiceFile
    ) {
      setModalError("Please provide at least one field to update.");
      return;
    }

    setIsSubmitting(true);

    try {
      // Array to hold promises for each update operation
      const updatePromises = [];

      // Update Order State if provided and different from current state
      if (
        newStatus.trim() &&
        newStatus.toLowerCase() !== order.state.toLowerCase()
      ) {
        updatePromises.push(
          updateOrderState(order.order_id, newStatus.toLowerCase())
        );
      }

      // Update Fulfillment Type if provided and different from current type
      if (
        newFulfillmentType.trim() &&
        newFulfillmentType !== order.fulfillment_type
      ) {
        updatePromises.push(
          updateOrderFulfillment(order.order_id, newFulfillmentType)
        );
      }

      // Create Invoice if invoiceId or invoiceFile is provided
      if (invoiceId.trim() || invoiceFile) {
        updatePromises.push(
          createInvoice({
            order_id: order.order_id,
            invoiceId: invoiceId.trim(),
            invoiceFile,
          })
        );
      }

      // Execute all update operations concurrently
      await Promise.all(updatePromises);

      onClose(); // This will trigger the re-fetch in OrderDetail
    } catch (error) {
      console.error("Update Order Modal Error:", error);
      // Check if the error is from the backend and has a specific message
      if (error.message) {
        setModalError(error.message);
      } else {
        setModalError("Failed to update the order. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Close the modal when the user presses the Escape key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener("keydown", handleEsc);
    }
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !order) {
    return null; // Don't render the modal if it's not open or the order is undefined
  }

  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-labelledby="update-order-modal-title"
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50"
    >
      <div
        className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg relative"
        role="document"
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          aria-label="Close Modal"
        >
          &times;
        </button>

        <h2
          id="update-order-modal-title"
          className="text-2xl font-semibold mb-4"
        >
          Update Order
        </h2>

        {/* Display Modal Error */}
        {modalError && (
          <div className="mb-4 p-2 bg-red-100 text-red-700 border border-red-400 rounded">
            {modalError}
          </div>
        )}

        {/* Status Update */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="status">
            Update Status:
          </label>
          <select
            id="status"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            className="w-full border border-gray-300 rounded p-2"
          >
            <option value="">-- Select Status --</option>
            <option value="placed">PLACED</option>
            <option value="cancelled">CANCELLED</option>
            <option value="delivered">DELIVERED</option>
            {/* Add other valid states as needed */}
          </select>
        </div>

        {/* Fulfillment Type Update */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="fulfillmentType">
            Update Fulfillment Type:
          </label>
          <select
            id="fulfillmentType"
            value={newFulfillmentType}
            onChange={(e) => setNewFulfillmentType(e.target.value)}
            className="w-full border border-gray-300 rounded p-2"
          >
            <option value="">-- Select Fulfillment Type --</option>
            <option value="JIT">JIT</option>
            <option value="INVENTORY">INVENTORY</option>
          </select>
        </div>

        {/* Invoice ID */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="invoiceId">
            Invoice ID:
          </label>
          <input
            type="text"
            id="invoiceId"
            value={invoiceId}
            onChange={(e) => setInvoiceId(e.target.value)}
            className="w-full border border-gray-300 rounded p-2"
            placeholder="Enter Invoice ID"
          />
        </div>

        {/* Invoice File Upload */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2" htmlFor="invoiceFile">
            Upload Invoice:
          </label>
          <input
            type="file"
            id="invoiceFile"
            accept=".pdf,.jpg,.png,.jpeg"
            onChange={(e) => setInvoiceFile(e.target.files[0])}
            className="w-full border border-gray-300 rounded p-2"
          />
          {invoiceFile && (
            <p className="text-sm text-gray-600 mt-1">
              Selected File: {invoiceFile.name}
            </p>
          )}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 mr-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            onClick={handleUpdate}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
            ) : null}
            {isSubmitting ? "Updating..." : "Update Order"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdateOrderModal;
